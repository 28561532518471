@import "~antd/dist/antd.less";

.ant-pro-form-login-logo {
  width: 60px !important;
}

@media (max-width: @screen-xs) {
  .ant-menu-item{
    margin: 30px 0px !important;
  }
  .ant-table {
    width: 100%;
    overflow-x: auto;
    &-thead > tr,
    &-tbody > tr {
      > th,
      > td {
        white-space: pre;
        > span {
          display: block;
        }
      }
    }
  }
}

// Compatible with IE11
@media screen and(-ms-high-contrast: active), (-ms-high-contrast: none) {
  body .ant-design-pro > .ant-layout {
    min-height: 100vh;
  }
}

.ant-pro-global-header-layout-mix {
  /* background-color: #00707e; */
}

.ant-pro-table .ant-card-body {
  padding: 12px;
}

.ant-tag,
.ant-tag a {
  border-radius: 8px !important;
}

.ant-image-mask,
.ant-image-img {
  padding: 5px;
  border-radius: 12px !important;
}

.ant-image-mask {
  /* background-color: #006f7e41 !important; */
}

.productDiscountInput .ant-input-number-group-addon {
  background-color: unset !important;
  border: none !important;
}

.pdf-viewer {
  margin: 1em 0;
}

.pdf-viewer .react-pdf__Document {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pdf-viewer .react-pdf__Page {
  max-width: calc(100% - 2em);
  margin: 1em;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
}

.pdf-viewer .react-pdf__Page canvas {
  max-width: 100%;
  height: auto !important;
}

.pdf-viewer .react-pdf__message {
  padding: 20px;
  color: white;
}

.sigCanvas {
  background-color: #fff;
  border-radius: 15px;
}

/* ANT Design dominant rounded corners  */

.ant-card {
  border-radius: 15px !important;
}

.ant-pro-table-search {
  border-radius: 15px;
}

.ant-btn {
  border-radius: 12px;
}

.ant-radio-button-wrapper:first-child {
  border-radius: 10px 0 0 10px !important;
}

.ant-radio-button-wrapper:last-child {
  border-radius: 0 10px 10px 0 !important;
}

.ant-input-affix-wrapper,
.ant-input {
  border-radius: 6px !important;
}

.ant-modal-header {
  border-radius: 12px 12px 0 0 !important;
}

.ant-modal-content {
  border-radius: 12px !important;
}

.ant-pro-top-nav-header-main-left {
  margin-right: 2em !important;
}

.ant-message-notice-content {
  border-radius: 10px !important;
}

div,
span,
p {
  font-size: 16px;
}

button {
  line-height: 16px !important;
}

h4 {
  font-size: 18px !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 10px !important;
}
.ant-card-actions {
  border-radius: 0 0 15px 15px !important;
}
.ant-card-cover img {
  border-radius: 15px 15px 0 0 !important;
}

.product-grid-title {
  font-size: 14px !important;
}

.ant-card-body {
  padding: 10px !important;
}

.bigbadgedot .ant-badge-status-dot {
  width: 10px;
  height: 10px;
}

.ant-badge-status {
  line-height: 1 !important;
}

.ant-drawer .ant-drawer-content {
  border-radius: 20px 0 0 20px;
}

.description-text {
  color: #666565 !important;
  font-weight: normal !important;
  font-size: 14px !important;
}

.ant-steps-item-icon,
.ant-steps-item-content {
  vertical-align: text-bottom !important;
}

.ant-modal-header,
.ant-drawer-header {
  background: #e5eaff !important;
}

.image-upload .ant-upload-list-picture .ant-upload-list-item,
.ant-upload-list-picture-card .ant-upload-list-item {
  padding: 4px !important;
  border-radius: 15px !important;
}

.image-upload .ant-upload-list-picture-card-container {
  width: 144px !important;
  height: 144px !important;
}

.ant-pagination-item,
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  border-radius: 8px !important;
}

.ant-collapse-borderless {
  background-color: #e5eaff !important;
  border-radius: 15px !important;
}

.sb-avatar {
  vertical-align: top !important;
}

.product-types-collapse.ant-collapse-borderless > .ant-collapse-item:hover {
  background-color: #f0f2f5 !important;
}

.product-types-collapse {
  background-color: #fff !important;
}

.ant-tag,
.ant-tag a,
.ant-tag a:hover {
  color: rgb(80 80 82) !important;
  font-weight: 500 !important;
  border: none !important;
}

.salesreptag {
  background: #ebebeb !important;
}

.ant-pro-card-body {
  padding: 0 !important;
}

.filter-top-row .ant-col {
  margin: 5px !important;
}

.ant-tag-green {
  background: #d4ffa9 !important;
}

@primary-color: #2F54EB;@border-radius-base: 12px;@font-size-base: 18px;