@import '~antd/es/style/themes/default.less';

.main {
  :global {
    .ant-descriptions-row > td {
      padding-bottom: 8px;
    }
    .ant-page-header-heading-extra {
      flex-direction: column;
    }
  }
}
.ant-card-head-title {
  overflow:visible;
}

.headerList {
  margin-bottom: 4px;
  :global {
    .ant-descriptions-row > td {
      padding-bottom: 8px;
    }
  }

  .stepDescription {
    position: relative;
    left: 38px;
    padding-top: 8px;
    font-size: 14px;
    text-align: left;

    > div {
      margin-top: 8px;
      margin-bottom: 4px;
    }
  }
}

.pageHeader {
  :global {
    .ant-page-header-heading-extra > * + * {
      margin-left: 8px;
    }
  }
  .moreInfo {
    display: flex;
    justify-content: space-between;
  }
}
.new_order_container{
  overflow:hidden;
  width:90vw;
}

@media screen and (max-width: @screen-sm) {
  .stepDescription {
    left: 8px;
  }
  .pageHeader {
    :global {
      .ant-pro-page-header-wrap-row {
        flex-direction: column;
      }
    }
  }
  .new_order_column{
    max-width: 300px;
  }
  .ant-modal{
    width: 90% !important;
  }
}

.OrderProductInput{
  border:none
}


@primary-color: #2F54EB;@border-radius-base: 12px;@font-size-base: 18px;