@import "~antd/es/style/themes/default.less";

@pro-header-hover-bg: rgba(0, 0, 0, 0.025);

.menu {
  .anticon {
    margin-right: 8px;
  }
  .ant-dropdown-menu-item {
    min-width: 160px;
  }
}
.sync_zoho{
cursor: pointer;
}

.right {
  display: flex;
  float: right;
  height: 48px;
  margin-left: auto;
  overflow: hidden;
  .action {
    cursor: pointer;
    transition: all 0.3s;
    > span {
      vertical-align: middle;
    }
    &:hover {
      background: @pro-header-hover-bg;
    }
    &.opened {
      background: @pro-header-hover-bg;
    }
  }
  .search {
    padding: 0 12px;
    &:hover {
      background: transparent;
    }
  }
  .account {
    .avatar {
      margin-right: 8px;
      color: @primary-color;
      vertical-align: top;
      background: rgba(255, 255, 255, 0.85);
    }
  }
}

.dark {
  .action {
    &:hover {
      background: #252a3d;
    }
    &.opened {
      background: #252a3d;
    }
  }
}

@media only screen and (max-width: @screen-md) {
  .ant-divider-vertical {
    vertical-align: unset;
  }
  .name {
    display: none;
  }
  .right {
    position: absolute;
    top: 0;
    right: 12px;
    .account {
      .avatar {
        margin-right: 0;
      }
    }
    .search {
      display: none;
    }
  }
}

@primary-color: #2F54EB;@border-radius-base: 12px;@font-size-base: 18px;