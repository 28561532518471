@import '~antd/es/style/themes/default.less';


@media (max-width: @screen-xs) {
    .order_radio {
        display: flex !important;
        flex-direction: column !important;
    }
  }

@primary-color: #2F54EB;@border-radius-base: 12px;@font-size-base: 18px;