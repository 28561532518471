@import "~antd/es/style/themes/default.less";

.popover .ant-spin-nested-loading > * {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 6px 16px -8px rgb(0 0 0 / 8%), 0 9px 28px 0 rgb(0 0 0 / 5%),
    0 12px 48px 16px rgb(0 0 0 / 3%);
}

.list {
  max-height: 500px;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  .item {
    padding-right: 24px;
    padding-left: 24px;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.3s;

    .meta {
      width: 100%;
    }

    .avatar {
      margin-top: 4px;
      background: @component-background;
    }
    .iconElement {
      font-size: 32px;
    }

    &.read {
      opacity: 0.4;
    }
    &:last-child {
      border-bottom: 0;
    }
    &:hover {
      background: @primary-1;
    }
    .title {
      margin-bottom: 8px;
      font-weight: normal;
    }
    .description {
      font-size: 12px;
      line-height: @line-height-base;
    }
    .datetime {
      margin-top: 4px;
      font-size: 12px;
      line-height: @line-height-base;
    }
    .extra {
      float: right;
      margin-top: -1.5px;
      margin-right: 0;
      color: @text-color-secondary;
      font-weight: normal;
    }
  }
  .loadMore {
    padding: 8px 0;
    color: @primary-6;
    text-align: center;
    cursor: pointer;
    &.loadedAll {
      color: rgba(0, 0, 0, 0.25);
      cursor: unset;
    }
  }
}

.notFound {
  padding: 73px 0 88px;
  color: @text-color-secondary;
  text-align: center;
  img {
    display: inline-block;
    height: 76px;
    margin-bottom: 16px;
  }
}

.bottomBar {
  height: 46px;
  color: @text-color;
  line-height: 46px;
  text-align: center;
  border-top: 1px solid @border-color-split;
  border-radius: 0 0 @border-radius-base @border-radius-base;
  transition: all 0.3s;
  div {
    display: inline-block;
    width: 50%;
    cursor: pointer;
    transition: all 0.3s;
    user-select: none;

    &:only-child {
      width: 100%;
    }
    &:not(:only-child):last-child {
      border-left: 1px solid @border-color-split;
    }
  }
}

@primary-color: #2F54EB;@border-radius-base: 12px;@font-size-base: 18px;