@import '~antd/es/style/themes/default.less';

.container > * {
  background-color: @popover-bg;
  border-radius: 4px;
  box-shadow: @shadow-1-down;
}

@media screen and (max-width: @screen-xs) {
  .container {
    width: 100% !important;
  }
  .container > * {
    border-radius: 0 !important;
  }
}

@primary-color: #2F54EB;@border-radius-base: 12px;@font-size-base: 18px;