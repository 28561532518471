@import "~antd/es/style/themes/default.less";

.login-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
  background: @layout-body-background;
}

.lang {
  width: 100%;
  height: 40px;
  line-height: 44px;
  text-align: right;
  :global(.ant-dropdown-trigger) {
    margin-right: 24px;
  }
}

.login-content {
  flex: 1;
  padding: 32px 0;
}

@media (min-width: @screen-md-min) {
  .login-container {
    background-image: url("https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg");
    background-repeat: no-repeat;
    background-position: center 110px;
    background-size: 100%;
  }

  .login-content {
    padding: 32px 0 24px;
  }
}

.icon {
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.2);
  font-size: 24px;
  vertical-align: middle;
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: @primary-color;
  }
}

@primary-color: #2F54EB;@border-radius-base: 12px;@font-size-base: 18px;