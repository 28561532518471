@import "~antd/es/style/themes/default.less";

.popover {
  position: relative;
  width: 400px;
}

.noticeButton {
  display: inline-block;
  cursor: pointer;
  transition: all 0.3s;
}
.icon {
  padding: 4px;
  vertical-align: middle;
}

.badge {
  font-size: 16px;
}

.tabs {
  .ant-tabs-nav-list {
    margin: auto;
  }

  .ant-tabs-nav-scroll {
    text-align: center;
  }
  .ant-tabs-bar {
    margin-bottom: 0;
  }
}

@primary-color: #2F54EB;@border-radius-base: 12px;@font-size-base: 18px;